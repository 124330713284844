var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"m-0 mb-2 pb-2 text-center"},[_vm._v("Bill Payment")]),_c('validation-observer',{ref:"billPaymentFormValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',[_c('b-row',{staticClass:"mx-5 px-5"},[_c('b-col',{attrs:{"sm":"6","md":"6","lg":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"consumer"}},[_c('label',[_vm._v("Voucher Number")]),_c('validation-provider',{attrs:{"name":"consumer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"consumer","state":errors.length > 0 ? false : null,"placeholder":"Voucher Number"},model:{value:(_vm.consumer),callback:function ($$v) {_vm.consumer=$$v},expression:"consumer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6","md":"6","lg":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"amount"}},[_c('label',[_vm._v("Amount")]),_c('validation-provider',{attrs:{"name":"amount","rules":{ required: _vm.required, regex: /^[0-9]+\.[0-9]{2}$/i }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"amount","state":errors.length > 0 ? false : null,"placeholder":"Amount"},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"mx-5 px-5"},[_c('b-col',{attrs:{"sm":"6","md":"7","lg":"8","xl":"9"}},[_c('b-form-group',{attrs:{"label-for":"tranDate"}},[_c('label',[_vm._v("Transaction Date")]),_c('validation-provider',{attrs:{"name":"tranDate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tranDate","type":"date","state":errors.length > 0 ? false : null,"placeholder":"Transaction Date"},model:{value:(_vm.tranDate),callback:function ($$v) {_vm.tranDate=$$v},expression:"tranDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6","md":"5","lg":"4","xl":"3"}},[_c('label',{attrs:{"for":"trantime"}},[_vm._v("Transaction Time")]),_c('b-input-group',{staticClass:"mb-3"},[_c('validation-provider',{attrs:{"name":"tranTime","rules":{ required: _vm.required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-row',[_c('b-col',{staticClass:"mr-0 pr-0",attrs:{"sm":"10","md":"10","lg":"10","xl":"10"}},[_c('b-form-input',{attrs:{"id":"trantime","type":"text","placeholder":"HH:mm:ss"},model:{value:(_vm.tranTime),callback:function ($$v) {_vm.tranTime=$$v},expression:"tranTime"}})],1),_c('b-col',{staticClass:"ml-0 pl-0",attrs:{"sm":"2","md":"2","lg":"2","xl":"2"}},[_c('b-input-group-append',[_c('b-form-timepicker',{attrs:{"button-only":"","right":"","show-seconds":"","locale":"en","aria-controls":"tranTime","no-close-button":""},model:{value:(_vm.tranTime),callback:function ($$v) {_vm.tranTime=$$v},expression:"tranTime"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',[_c('b-button',{staticClass:"mr-1 float-right",attrs:{"id":"submit","type":"submit","variant":"primary","pill":""},on:{"click":_vm.validationForm}},[_vm._v(" Submit ")])],1)],1)])],1),_c('b-overlay',{attrs:{"show":_vm.show,"spinner-variant":"primary","no-wrap":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }