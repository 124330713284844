<template>
  <div>
    <h2 class="m-0 mb-2 pb-2 text-center">Bill Inquiry</h2>
    <validation-observer ref="billInquiryFormValidation">
      <b-form @submit.prevent>
        <div>
          <b-row class="mx-5 px-5">
            <b-col sm="12" md="12" lg="12" xl="12">
              <b-form-group label-for="consumer">
                <label>Voucher Number</label>
                <validation-provider
                  #default="{ errors }"
                  name="consumer"
                  rules="required"
                >
                  <b-form-input
                    id="consumer"
                    v-model="consumer"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Voucher Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- <b-col md="4">
              <b-form-group label-for="bank_mnemonic">
                <label>Bank Mnemonic</label>
                <validation-provider
                  #default="{ errors }"
                  name="bank_mnemonic"
                  rules="required"
                >
                  <b-form-input
                    id="bank_mnemonic"
                    v-model="bank_mnemonic"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Bank Mnemonic"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label-for="reserved">
                <label>Reserved</label>
                <validation-provider
                  #default="{ errors }"
                  name="reserved"
                  :rules="{ required }"
                >
                  <b-form-input
                    id="reserved"
                    v-model="reserved"
                    :state="errors.length > 0 ? false : null"
                    placeholder="reserved"
                    class="text-black"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
          </b-row>
          <b-form-group>
            <b-button
              id="submit"
              type="submit"
              variant="primary"
              pill
              class="mr-1 float-right"
              @click="validationForm"
            >
              Submit
            </b-button>
          </b-form-group>
        </div>
      </b-form>
    </validation-observer>
    <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [util],
  data() {
    return {
      required,
      show: false,
      consumer: "",
      // bank_mnemonic: "",
      // reserved: "",
    };
  },
  methods: {
    ...mapActions({
      getBillInquiry: "appData/getBillInquiry",
    }),

    async validationForm() {
      const success = await this.$refs.billInquiryFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },

    async submit() {
      this.show = true;
      try {
        const payload = {
          consumer_number: this.consumer,
          // bankMnemonic: this.bank_mnemonic,
          // reserved: this.reserved,
        };

        const res = await this.getBillInquiry(payload);
        
        if (res.status === 200) {
       
            this.$swal({
              title: res.data.msg,
              icon: "success",
              timer: 3000,
            });
            this.show = false;
            this.reset();
            this.$refs.billPaymentFormValidation.reset();
          
        }

        if (res.status === 400 || res.status === 404) {
            this.$swal({
              title: res.data.msg,
              icon: "danger",
              timer: 3000,
            });
            this.show = false;
            this.reset();
            this.$refs.billPaymentFormValidation.reset();
        }
          if (res.status === 405) {
            this.$swal({
              title: res.data.msg,
              icon: "warning",
              timer: 3000,
            });
            this.show = false;
            this.reset();
            this.$refs.billPaymentFormValidation.reset();
        }
      } catch (error) {
        this.show = false;
        this.displayError(error);
        this.reset();
      }
    },

    async reset() {
      this.consumer = "";
      this.bank_mnemonic = "";
      this.reserved = "";
      this.$refs.billInquiryFormValidation.reset();
    },
  },
  async mounted() {},

  computed: {
    ...mapGetters({
      getUser: "appData/getUser",
      getLoggedInUser: "appData/getUser",
    }),
  },
};
</script>
<style>
.text-black {
  color: black !important;
  /* font-size: 1.2rem; */
}
/* label {
  font-size: 110%;
  font-weight: 550;
  color: #000;
} */
</style>


<style lang="scss" scoped>
@import "@core/scss/vue/libs/quill.scss";
// label {
//   font-size: 110%;
//   font-weight: 550;
//   color: #000;
// }
</style>
