<template>
  <div>
    <h2 class="m-0 mb-2 pb-2 text-center">Bill Payment</h2>
    <validation-observer ref="billPaymentFormValidation">
      <b-form @submit.prevent>
        <div>
          <b-row class="mx-5 px-5">
            <b-col sm="6" md="6" lg="6" xl="6">
              <b-form-group label-for="consumer">
                <label>Voucher Number</label>
                <validation-provider
                  #default="{ errors }"
                  name="consumer"
                  rules="required"
                >
                  <b-form-input
                    id="consumer"
                    v-model="consumer"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Voucher Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ^[0-9]*\.[0-9]{2}$ or ^[0-9]*\.[0-9][0-9]$  -->
            <!-- ^\-?[0-9]+(?:\.[0-9]{1,2})?$ -->
            <b-col sm="6" md="6" lg="6" xl="6">
              <b-form-group label-for="amount">
                <label>Amount</label>
                <validation-provider
                  #default="{ errors }"
                  name="amount"
                  :rules="{ required, regex: /^[0-9]+\.[0-9]{2}$/i }"
                >
                  <b-form-input
                    id="amount"
                    v-model="amount"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Amount"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mx-5 px-5">
            <b-col sm="6" md="7" lg="8" xl="9">
              <b-form-group label-for="tranDate">
                <label>Transaction Date</label>
                <validation-provider
                  #default="{ errors }"
                  name="tranDate"
                  rules="required"
                >
                  <b-form-input
                    id="tranDate"
                    type="date"
                    v-model="tranDate"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Transaction Date"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6" md="5" lg="4" xl="3">
              <label for="trantime">Transaction Time</label>
              <b-input-group class="mb-3">
                <validation-provider
                  #default="{ errors }"
                  name="tranTime"
                  :rules="{ required }"
                >
                  <b-row>
                    <b-col sm="10" md="10" lg="10" xl="10" class="mr-0 pr-0">
                      <b-form-input
                        id="trantime"
                        v-model="tranTime"
                        type="text"
                        placeholder="HH:mm:ss"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col sm="2" md="2" lg="2" xl="2" class="ml-0 pl-0">
                      <b-input-group-append>
                        <b-form-timepicker
                          v-model="tranTime"
                          button-only
                          right
                          show-seconds
                          locale="en"
                          aria-controls="tranTime"
                          no-close-button
                        ></b-form-timepicker>
                      </b-input-group-append>
                    </b-col>
                  </b-row>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-input-group>
            </b-col>
          </b-row>
          <b-form-group>
            <b-button
              id="submit"
              type="submit"
              variant="primary"
              pill
              class="mr-1 float-right"
              @click="validationForm"
            >
              Submit
            </b-button>
          </b-form-group>
        </div>
      </b-form>
    </validation-observer>
    <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [util],
  data() {
    return {
      required,
      show: false,
      consumer: "",
      amount: "",
      tranTime: "",
      tranDate: "",
      bankMnemonic: "",
      reserved: "",
    };
  },
  methods: {
    ...mapActions({
      makeBillPayment: "appData/makeBillPayment",
    }),

    async validationForm() {
      const success = await this.$refs.billPaymentFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },

    async submit() {
      this.show = true;
      // let temp = this.amount.replace('.','')
      // temp = temp.padStart(13, '0');
      try {
        const payload = {
          consumer_number: this.consumer,
          // amount: this.amount,
          transaction_amount: this.amount.replace(".", "").padStart(12, "0"),
          // amount: temp,
          tran_time: this.tranTime.replaceAll(":", ""),
          tran_date: this.tranDate.replaceAll("-", ""),
          // bankMnemonic: this.bankMnemonic,
          // reserved: this.reserved,
        };

        const res = await this.makeBillPayment(payload);

        if (res.status === 200) {
          this.$swal({
            title: res.data.msg,
            icon: "success",
            timer: 3000,
          });
          this.show = false;
          this.reset();
          this.$refs.billPaymentFormValidation.reset();
        }

        if (res.status === 400 || res.status === 404) {
          this.$swal({
            title: res.data.msg,
            icon: "danger",
            timer: 3000,
          });
          this.show = false;
          this.reset();
          this.$refs.billPaymentFormValidation.reset();
        }
        if (res.status === 405) {
          this.$swal({
            title: res.data.msg,
            icon: "warning",
            timer: 3000,
          });
          this.show = false;
          this.reset();
          this.$refs.billPaymentFormValidation.reset();
        }
      } catch (error) {
        this.show = false;
        this.displayError(error);
        this.reset();
      }
    },

    async reset() {
      // this.consumer = "";
      // this.amount = "";
      // this.tranTime = "";
      // this.$refs.fileInput.reset();
      // this.$refs.billPaymentFormValidation.reset();
    },
  },

  async mounted() {
    const userData = await this.getUser;
    this.cnic = userData.cnic;
    this.name = userData.name;
  },
  computed: {
    ...mapGetters({
      getUser: "appData/getUser",
      getLoggedInUser: "appData/getUser",
    }),
  },
};
</script>
<style>
.text-black {
  color: black !important;
  /* font-size: 1.2rem; */
}
/* label {
  font-size: 110%;
  font-weight: 550;
  color: #000;
} */
</style>


<style lang="scss" scoped>
@import "@core/scss/vue/libs/quill.scss";
// label {
//   font-size: 110%;
//   font-weight: 550;
//   color: #000;
// }
</style>
