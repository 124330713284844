<template>
  <div>
    <div class="mb-1">
      <b-card>
        <BillInquiry />
      </b-card>

      <b-card>
        <BillPayment />
      </b-card>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
import BillInquiry from "@/components/booking/admin/BillInquiry.vue";
import BillPayment from "@/components/booking/admin/BillPayment.vue";
export default {
  components: {
    BillInquiry,
    BillPayment,
  },
  data() {
    return {};
  },
  mixins: [util],
  async mounted() {},
  methods: {
    ...mapActions({}),
  },
  computed: {
    ...mapGetters({ getUser: "appData/getUser", hasRole: "appData/hasRole" }),
  },

  watch: {},
};
</script>
<style>
label {
  font-size: 110%;
  font-weight: 550 !important;
}
</style>
<style lang="scss">
@import "~@assets/scss/variables/_variables.scss";
</style>
